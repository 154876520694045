<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных склада
      </h4>
      <div class="alert-body">
        Не найден склад с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'warehouses'}"
        >
          Складов
        </b-link>
        и выбрать другой.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Контактная информация"
              label-for="contact_information"
            >
              <b-form-textarea
                id="contact_information"
                v-model.trim="item.contact_information"
                placeholder="Контактная информация"
                :state="get(validation, 'contact_information[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'contact_information[0]')"
                class="text-danger"
              >
                {{ get(validation, 'contact_information[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <div class="d-flex mt-2 mb-1">
              <feather-icon
                icon="CalendarIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                График работы по дням недели
              </h4>
            </div>
          </b-col>
          <b-col
            v-for="day in weekDays"
            :key="day.key"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="day.label"
              :label-for="day.key"
            >
              <b-form-input
                :id="day.key"
                v-model.trim="item.working_schedule[day.key]"
                placeholder="График работы"
                :state="get(validation, `working_schedule.${day.key}[0]`) ? false : null"
              />
              <small
                v-if="!!get(validation, `working_schedule.${day.key}[0]`)"
                class="text-danger"
              >
                {{ get(validation, `working_schedule.${day.key}[0]`) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="my-1"
          >
            <address-field v-model="item.real_address" />
          </b-col>
        </b-row>

        <b-row class="mt-1 mb-3">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateItem"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow, BTab, BTabs,
  BFormTextarea, BInputGroup, BCardHeader,
  BCardTitle, BTable, BFormCheckbox, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import get from 'lodash/get'
import { getCurrentInstance, ref, onMounted } from 'vue'
import { updateWarehouse as updateWarehouseApi, getWarehouse as getWarehouseApi } from '@/services/main-api/shop/warehouses'
import useCrudEdit from '@/composables/useCrudEdit'
import AddressField from '@/components/AddressField.vue'
import { updateAddress } from '@/services/main-api/geolocation/addresses'

export default {
  name: 'EditWarehouseView',
  components: {
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BTab,
    BTabs,
    BFormCheckbox,
    BFormTextarea,
    BInputGroup,
    BCardHeader,
    BCardTitle,
    BTable,
    BAvatar,
    AddressField,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)
    const weekDays = [
      { label: 'Понедельник', key: 'monday' },
      { label: 'Вторник', key: 'tuesday' },
      { label: 'Среда', key: 'wednesday' },
      { label: 'Четверг', key: 'thursday' },
      { label: 'Пятница', key: 'friday' },
      { label: 'Суббота', key: 'saturday' },
      { label: 'Воскресенье', key: 'sunday' },
    ]

    const getItem = async () => {
      try {
        const res = await getWarehouseApi({ id: props.id, includes: 'real_address' })
        instance.item = res.data.data
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const updateEntity = async () => {
      await updateWarehouseApi({
        id: instance.item.id,
        name: instance.item.name,
        contact_information: instance.item.contact_information,
        working_schedule: {
          monday: instance.item.working_schedule.monday,
          tuesday: instance.item.working_schedule.tuesday,
          wednesday: instance.item.working_schedule.wednesday,
          thursday: instance.item.working_schedule.thursday,
          friday: instance.item.working_schedule.friday,
          saturday: instance.item.working_schedule.saturday,
          sunday: instance.item.working_schedule.sunday,
        },
      })
      await updateAddress(instance.item.real_address)
    }

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        name: '',
        contact_information: '',
        working_schedule: {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
        },
        real_address: {
        },
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      getItem,
      updateEntity,
      getTextAfterUpdate: () => 'Склад был изменен.',
      runAfterUpdate: async () => {
        await instance.$router.go(-1)
      },
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        this.$router.push({ name: 'dashboard' })
      }
    })

    return {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,

      get,
      getItemError,

      weekDays,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
